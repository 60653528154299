import React from 'react';
import QuoteDisplay from '../quotesystem';

const Tasks = () => {
  return (
    <div className="dashboard">
      <h1>Welcome to Your Tasks</h1>
      <QuoteDisplay />
     
      {/* Add more dashboard content here */}
    </div>
  );
};

export default Tasks;