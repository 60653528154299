import React from 'react';
import QuoteDisplay from '../quotesystem';
import './styles/dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <h1>Welcome to Your Dashboard</h1>
      <QuoteDisplay />
     
      {/* Add more dashboard content here */}
    </div>
  );
};

export default Dashboard;