import React, { useState } from 'react';
import './header.css';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header>
      <h1>Flowify</h1>
      <div className="user-profile" onClick={toggleDropdown}>
        <img src="https://via.placeholder.com/40" alt="User Profile" id="userProfilePic" />
        {isDropdownOpen && (
          <div className="dropdown-content">
            <a href="#">Profile</a>
            <a href="#">Settings</a>
            <a href="#">Logout</a>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;