import React, { useState } from 'react';
import { Activity, Book, DollarSign, Coffee, Music, Briefcase, Heart, Star, Sun, Moon } from 'lucide-react';

const iconOptions = [
  { name: 'Activity', component: Activity },
  { name: 'Book', component: Book },
  { name: 'DollarSign', component: DollarSign },
  { name: 'Coffee', component: Coffee },
  { name: 'Music', component: Music },
  { name: 'Briefcase', component: Briefcase },
  { name: 'Heart', component: Heart },
  { name: 'Star', component: Star },
  { name: 'Sun', component: Sun },
  { name: 'Moon', component: Moon },
];

const IconSelector = ({ selectedIcon, onSelectIcon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const SelectedIconComponent = iconOptions.find(icon => icon.name === selectedIcon)?.component || Activity;

  return (
    <div className="icon-selector-container">
      <button className="selected-icon" onClick={() => setIsOpen(true)}>
        <SelectedIconComponent size={24} />
      </button>
      {isOpen && (
        <div className="icon-popup-overlay" onClick={() => setIsOpen(false)}>
          <div className="icon-popup" onClick={(e) => e.stopPropagation()}>
            {iconOptions.map((icon) => (
              <button
                key={icon.name}
                className="icon-option"
                onClick={() => {
                  onSelectIcon(icon.name);
                  setIsOpen(false);
                }}
              >
                {React.createElement(icon.component, { size: 24 })}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default IconSelector;