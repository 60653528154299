import React, { useState, useEffect } from 'react';
import './app.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './layout/sidebar';
import Header from './layout/header';

import Home from './modules/home';
import Login from './modules/login';
import Signup from './modules/signup';
import Dashboard from './modules/dashboard';
import Habits from './modules/habits';
import Tasks from './modules/tasks';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas);

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);     

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    // Check if user is logged in (e.g., by checking for a token in localStorage)
    const checkLoginStatus = () => {
    const token = localStorage.getItem('token');
    if (token) {
      // Optionally, validate the token with your backend
      setIsLoggedIn(true);
    }
    setIsLoading(false);
  };

  checkLoginStatus();
}, []);

if (isLoading) {
  return <div>Loading...</div>; // Or a loading spinner
}

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`app ${isDarkMode ? 'dark-mode' : ''}`}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route path="/signup" element={<Signup setIsLoggedIn={setIsLoggedIn} />} />
        
        <Route
          path="/*"
          element={
            isLoggedIn ? (
              <div className="app-layout">
                <Sidebar isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
                <div className="main-content">
                  <Header />
                  <hr />
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/affirmations" element={<div>Affirmations</div>} />
                    <Route path="/calendar" element={<div>Calendar</div>} />
                    <Route path="/goals" element={<div>Goals</div>} />
                    <Route path="/habits" element={<Habits/>} />
                    <Route path="/tasks" element={<Tasks/>} />
                    <Route path="/journal" element={<div>Journal</div>} />
                    <Route path="/quotes" element={<div>Quotes</div>} />
                    <Route path="/articles" element={<div>Articles</div>} />
                    <Route path="/products" element={<div>Products</div>} />
                    <Route path="/about" element={<div>About</div>} />
                    <Route path="/settings" element={<div>Settings</div>} />
                    <Route path="/support" element={<div>Support</div>} />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                  </Routes>
                </div>
              </div>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
      </Routes>
    </div>
  );
};

export default App;