import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './styles/login.css';

axios.defaults.withCredentials = true;

const API_BASE_URL = 'http://localhost:5000';

const Signup = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    if (password !== confirmPassword) {
      setError("Passwords don't match");
      setIsLoading(false);
      return;
    }

    const signupData = {
      username: email.split('@')[0],
      email,
      password
    };

    try {
      const response = await axios.post(`${API_BASE_URL}/signup`, signupData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data.message);
      localStorage.setItem('token', response.data.token); // Store the token
      setIsLoggedIn(true);
      navigate('/dashboard');
    } catch (error) {
      if (error.response) {
        setError(error.response.data.error || 'An error occurred during signup');
        console.error('Error details:', error.response.data);
      } else if (error.request) {
        setError('No response from server. Please check your connection and try again.');
      } else {
        setError('An error occurred. Please try again.');
      }
      console.error('Error details:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h1>Sign up for Flowify</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <button type="submit" className="login-btn" disabled={isLoading}>
            {isLoading ? 'Processing...' : 'Sign Up'}
          </button>
        </form>
        <p>
          Already have an account?{' '}
          <button onClick={() => navigate('/login')} className="toggle-btn">
            Log in
          </button>
        </p>
      </div>
    </div>
  );
};

export default Signup;