import React from 'react';
import { Link } from 'react-router-dom';
import './styles/home.css';
import flowifyIcon from '../assets/sea.png';
const LandingPage = () => {
  return (
    <div className="landing-page">
      <header>
        <nav>
        <img src={flowifyIcon} alt="Flowify Logo" className="logo-icon" />
          <Link to="/" className="logo">Flowify</Link>

          
        </nav>
      </header>

      <main>
        <div className="hero-section">
          <h1>Transform Your Habits, Achieve Your Dreams</h1>
          <p>
            Welcome to Flowify, your guide to managing daily tasks and habits. 
            Every small step brings you closer to your goals. Stay calm, positive, and track your progress with Flowify.
          </p>
          <Link to="/login" className="cta-button">Start Your Journey Now</Link>
        </div>

        <section className="cards-section">
          <div className="card">
            <h2>Empower Your Day</h2>
            <p>Organize tasks and stay productive with a clear plan. Achieve more, one step at a time.</p>
          </div>
          <div className="card">
            <h2>Track Your Progress</h2>
            <p>Turn ambitions into realities by keeping track of habits and daily efforts.</p>
          </div>
          <div className="card">
            <h2>Stay Positive</h2>
            <p>Start each day with affirmations that lift your spirit and keep you motivated.</p>
          </div>
        </section>

        <section className="video-section">
          <h2>Discover How Flowify Helps You Succeed</h2>
          <p>Short videos guide you through using Flowify to reach your goals with ease and positivity.</p>
          <div className="videos">
            <video src="educational_video1.mp4" controls></video>
            <video src="educational_video2.mp4" controls></video>
          </div>
        </section>
      </main>

      <footer>
        <p>&copy; 2024 Flowify. Achieve More. Live Better.</p>
      </footer>
    </div>
  );
};

export default LandingPage;