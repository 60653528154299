import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faThumbtack, faAlignRight, faTachometerAlt, faComment, faCalendarAlt, 
  faBullseye, faChartLine, faTasks, faBook, faQuoteLeft, faNewspaper, 
  faShoppingCart, faInfoCircle, faCog, faQuestionCircle, faSun, faMoon 
} from '@fortawesome/free-solid-svg-icons';
import './sidebar.css';
import flowifyIcon from '../assets/sea.png';

const Sidebar = ({ isDarkMode, toggleDarkMode }) => {
  const [isPinned, setIsPinned] = useState(false);
  const location = useLocation();

  const togglePin = () => setIsPinned(!isPinned);
  const isActive = (path) => location.pathname === path ? 'active' : '';

  return (
    <div className={`sidebar ${isPinned ? 'pinned' : ''}`}>
      <div className="sidebar-header">
        <div className="product-name">
          <img src={flowifyIcon} alt="Flowify Icon" width="24" height="24" />
          <span className="flowify-text">Flowify</span>
        </div>
        <button className="pin-button" onClick={togglePin}>
          <FontAwesomeIcon icon={isPinned ? faThumbtack : faAlignRight} />
        </button>
      </div>

      <nav>
        <Link to="/dashboard" className={isActive('/dashboard')}><FontAwesomeIcon icon={faTachometerAlt} className="menu-icon" /><span className="menu-text">Dashboard</span></Link>
        <Link to="/affirmations" className={isActive('/affirmations')}><FontAwesomeIcon icon={faComment} className="menu-icon" /> <span className="menu-text">Affirmations</span></Link>
        <Link to="/calendar" className={isActive('/calendar')}><FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" /> <span className="menu-text">Calendar</span></Link>
        <Link to="/goals" className={isActive('/goals')}><FontAwesomeIcon icon={faBullseye} className="menu-icon" /> <span className="menu-text">Goals</span></Link>
        <Link to="/habits" className={isActive('/habits')}><FontAwesomeIcon icon={faChartLine} className="menu-icon" /> <span className="menu-text">Habits</span></Link>
        <Link to="/tasks" className={isActive('/tasks')}><FontAwesomeIcon icon={faTasks} className="menu-icon" /> <span className="menu-text">Tasks</span></Link>
        <Link to="/journal" className={isActive('/journal')}><FontAwesomeIcon icon={faBook} className="menu-icon" /> <span className="menu-text">Journal</span></Link>
        <Link to="/quotes" className={isActive('/quotes')}><FontAwesomeIcon icon={faQuoteLeft} className="menu-icon" /> <span className="menu-text">Quotes</span></Link>
        <Link to="/articles" className={isActive('/articles')}><FontAwesomeIcon icon={faNewspaper} className="menu-icon" /> <span className="menu-text">Articles</span></Link>
        <Link to="/products" className={isActive('/products')}><FontAwesomeIcon icon={faShoppingCart} className="menu-icon" /> <span className="menu-text">Products</span></Link>
        <Link to="/about" className={isActive('/about')}><FontAwesomeIcon icon={faInfoCircle} className="menu-icon" /> <span className="menu-text">About</span></Link>
        
        {/* Spacer to push bottom items to the bottom */}
        <div className="spacer"></div>

        {/* Bottom menu items */}
        <Link to="/settings" className={isActive('/settings')}><FontAwesomeIcon icon={faCog} className="menu-icon" /><span className="menu-text">Settings</span></Link>
        <Link to="/support" className={isActive('/support')}><FontAwesomeIcon icon={faQuestionCircle} className="menu-icon" /><span className="menu-text">Support</span></Link>
        <button onClick={toggleDarkMode} className="menu-item dark-mode-toggle">
          <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} className="menu-icon" />
          <span className="menu-text">{isDarkMode ? 'Day Mode' : 'Night Mode'}</span>
        </button>
      </nav>
    </div>
  );
};

export default Sidebar;