import React, { useState, useRef, useEffect } from 'react';
import { Plus, ChevronLeft, ChevronRight, Trash2, Activity, Book, DollarSign, Coffee, Music, Briefcase, Heart, Star, Sun, Moon } from 'lucide-react';
import QuoteDisplay from '../quotesystem';
import IconSelector from '../components/iconselector';

import './styles/habits.css';

const defaultHabits = [
  { id: 1, name: 'Exercise', description: 'Daily workout', priority: 'high', category: 'health', notes: '', icon: 'Activity' },
  { id: 2, name: 'Read', description: 'Read for 30 minutes', priority: 'medium', category: 'personal', notes: '', icon: 'Book' },
  { id: 3, name: 'Budget', description: 'Track expenses', priority: 'high', category: 'finance', notes: '', icon: 'DollarSign' },
];

const iconOptions = [
  { name: 'Activity', component: Activity },
  { name: 'Book', component: Book },
  { name: 'DollarSign', component: DollarSign },
  { name: 'Coffee', component: Coffee },
  { name: 'Music', component: Music },
  { name: 'Briefcase', component: Briefcase },
  { name: 'Heart', component: Heart },
  { name: 'Star', component: Star },
  { name: 'Sun', component: Sun },
  { name: 'Moon', component: Moon },
];

const Habit = () => {
  const [habits, setHabits] = useState(defaultHabits);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentHabit, setCurrentHabit] = useState(null);
  const [isIconSelectorOpen, setIsIconSelectorOpen] = useState(false);
  const scrollContainerRef = useRef(null);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;
        setShowLeftScroll(scrollLeft > 0);
        setShowRightScroll(scrollLeft < scrollWidth - clientWidth);
      }
    };

    checkScroll();
    scrollContainerRef.current.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);
    return () => {
      scrollContainerRef.current?.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, [habits]);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300;
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const handleAddHabit = (newHabit) => {
    setHabits([...habits, { ...newHabit, id: habits.length + 1 }]);
    setIsDialogOpen(false);
  };

  const handleEditHabit = (updatedHabit) => {
    setHabits(habits.map(habit => habit.id === updatedHabit.id ? updatedHabit : habit));
    setIsDialogOpen(false);
  };

  const handleDeleteHabit = (id) => {
    setHabits(habits.filter(habit => habit.id !== id));
  };

  const openEditDialog = (habit) => {
    setCurrentHabit(habit);
    setIsDialogOpen(true);
  };

  return (
    <div className="habit-tracker">
      <QuoteDisplay className="quote-display" />
      
      <div className="add-habit-container">
        <button 
          className="add-habit-button"
          onClick={() => { setCurrentHabit(null); setIsDialogOpen(true); }}
        >
          <Plus className="mr-2" /> Add Habit
        </button>
      </div>

      {isDialogOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>{currentHabit ? 'Edit Habit' : 'Add New Habit'}</h2>
            <HabitForm
              habit={currentHabit}
              onSave={currentHabit ? handleEditHabit : handleAddHabit}
              onCancel={() => setIsDialogOpen(false)}
              onOpenIconSelector={() => setIsIconSelectorOpen(true)}
            />
          </div>
        </div>
      )}

      {isIconSelectorOpen && (
        <div className="modal-overlay">
          <div className="icon-selector-modal">
            <IconSelector
              selectedIcon={currentHabit?.icon || 'Activity'}
              onSelectIcon={(icon) => {
                setCurrentHabit(prev => ({ ...prev, icon }));
                setIsIconSelectorOpen(false);
              }}
            />
          </div>
        </div>
      )}

      <div className="habit-grid-container">
        {showLeftScroll && (
          <button 
            className="nav-button nav-button-left"
            onClick={() => handleScroll('left')}
          >
            <ChevronLeft />
          </button>
        )}
        <div className="habit-grid" ref={scrollContainerRef}>
          {habits.map((habit) => (
            <HabitCard 
              key={habit.id} 
              habit={habit} 
              onEdit={openEditDialog} 
              onDelete={handleDeleteHabit}
            />
          ))}
        </div>
        {showRightScroll && (
          <button 
            className="nav-button nav-button-right"
            onClick={() => handleScroll('right')}
          >
            <ChevronRight />
          </button>
        )}
      </div>
    </div>
  );
};

const HabitForm = ({ habit, onSave, onCancel, onOpenIconSelector }) => {
  const [formData, setFormData] = useState(habit || {
    name: '',
    description: '',
    priority: '',
    category: '',
    notes: '',
    icon: 'Activity'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(formData);
  };

  const IconComponent = iconOptions.find(icon => icon.name === formData.icon)?.component || Activity;

  return (
    <form onSubmit={handleSubmit} className="habit-form">
      <div className="form-group">
        <label htmlFor="name">Habit name</label>
        <div className="input-with-icon">
          <input
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <button type="button" className="icon-button" onClick={onOpenIconSelector}>
            <IconComponent size={24} />
          </button>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <input
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="priority">Priority</label>
        <select
          id="priority"
          name="priority"
          value={formData.priority}
          onChange={handleChange}
        >
          <option value="">Select priority</option>
          <option value="critical">Critical</option>
          <option value="high">High</option>
          <option value="medium">Medium</option>
          <option value="low">Low</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="category">Category</label>
        <input
          id="category"
          name="category"
          placeholder="e.g., personal, finance, health"
          value={formData.category}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <label htmlFor="notes">Notes</label>
        <textarea
          id="notes"
          name="notes"
          value={formData.notes}
          onChange={handleChange}
        />
      </div>
      <div className="form-buttons">
        <button type="button" onClick={onCancel} className="cancel-button">
          Cancel
        </button>
        <button type="submit" className="save-button">
          Save
        </button>
      </div>
    </form>
  );
};

const HabitCard = ({ habit, onEdit, onDelete }) => {
  const IconComponent = iconOptions.find(icon => icon.name === habit.icon)?.component || Activity;

  return (
    <div className="habit-card" onClick={() => onEdit(habit)}>
      <div className="habit-icon">
        <IconComponent size={24} />
      </div>
      <h3>{habit.name}</h3>
      <p>{habit.description}</p>
      <div className="tags">
        <span className="tag priority">
          {habit.priority}
        </span>
        <span className="tag category">
          {habit.category}
        </span>
      </div>
      <button
        className="delete-button"
        onClick={(e) => {
          e.stopPropagation();
          onDelete(habit.id);
        }}
      >
        <Trash2 className="w-4 h-4" />
      </button>
    </div>
  );
};

export default Habit;