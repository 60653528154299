// QuoteSystem.js
import React, { useState, useEffect } from 'react';
import './quotesystem.css';

// This function simulates fetching a quote from an API
// Replace this with an actual API call in a real application
const fetchQuote = async () => {
  // Simulating API delay
  await new Promise(resolve => setTimeout(resolve, 500));
  
  const quotes = [
    { text: "The only way to do great work is to love what you do.", author: "Steve Jobs" },
    { text: "Life is what happens when you're busy making other plans.", author: "John Lennon" },
    { text: "The future belongs to those who believe in the beauty of their dreams.", author: "Eleanor Roosevelt" },
    { text: "Success is not final, failure is not fatal: it is the courage to continue that counts.", author: "Winston Churchill" },
    { text: "The only limit to our realization of tomorrow will be our doubts of today.", author: "Franklin D. Roosevelt" },
    { "text": "Believe you can and you're halfway there.", "author": "Theodore Roosevelt" },
    { "text": "You miss 100% of the shots you don’t take.", "author": "Wayne Gretzky" },
    { "text": "It does not matter how slowly you go as long as you do not stop.", "author": "Confucius" },
    { "text": "It always seems impossible until it’s done.", "author": "Nelson Mandela" },
    { "text": "Don’t watch the clock; do what it does. Keep going.", "author": "Sam Levenson" },
    { "text": "If you can dream it, you can do it.", "author": "Walt Disney" },
    { "text": "Hardships often prepare ordinary people for an extraordinary destiny.", "author": "C.S. Lewis" },
    { "text": "The only way to achieve the impossible is to believe it is possible.", "author": "Charles Kingsleigh" },
    { "text": "Dream big and dare to fail.", "author": "Norman Vaughan" },
    { "text": "You are never too old to set another goal or to dream a new dream.", "author": "C.S. Lewis" },
    { "text": "Act as if what you do makes a difference. It does.", "author": "William James" },
    { "text": "Keep your face always toward the sunshine—and shadows will fall behind you.", "author": "Walt Whitman" },
    { "text": "What lies behind us and what lies before us are tiny matters compared to what lies within us.", "author": "Ralph Waldo Emerson" },
    { "text": "Change your thoughts and you change your world.", "author": "Norman Vincent Peale" },
    { "text": "The best way to predict the future is to create it.", "author": "Peter Drucker" },
    { "text": "Failure will never overtake me if my determination to succeed is strong enough.", "author": "Og Mandino" },
    { "text": "Do not wait; the time will never be ‘just right.’ Start where you stand.", "author": "Napoleon Hill" },
    { "text": "Your time is limited, so don’t waste it living someone else’s life.", "author": "Steve Jobs" },
    { "text": "Success is not how high you have climbed, but how you make a positive difference to the world.", "author": "Roy T. Bennett" },
    { "text": "The secret of getting ahead is getting started.", "author": "Mark Twain" },
    { "text": "Go confidently in the direction of your dreams. Live the life you have imagined.", "author": "Henry David Thoreau" },
    { "text": "Don’t be afraid to give up the good to go for the great.", "author": "John D. Rockefeller" },
    { "text": "Do what you can, with what you have, where you are.", "author": "Theodore Roosevelt" },
    { "text": "Success usually comes to those who are too busy to be looking for it.", "author": "Henry David Thoreau" },
    { "text": "Opportunities don’t happen. You create them.", "author": "Chris Grosser" },
    { "text": "I find that the harder I work, the more luck I seem to have.", "author": "Thomas Jefferson" },
    { "text": "Don’t be distracted by criticism. Remember, the only taste of success some people get is to take a bite out of you.", "author": "Zig Ziglar" },
    { "text": "Success is walking from failure to failure with no loss of enthusiasm.", "author": "Winston Churchill" },
    { "text": "Try not to become a man of success. Rather become a man of value.", "author": "Albert Einstein" },
    { "text": "What you get by achieving your goals is not as important as what you become by achieving your goals.", "author": "Zig Ziglar" },
    { "text": "Don’t let yesterday take up too much of today.", "author": "Will Rogers" },
    { "text": "You learn more from failure than from success. Don’t let it stop you. Failure builds character.", "author": "Unknown" },
    { "text": "Experience is a hard teacher because she gives the test first, the lesson afterward.", "author": "Vernon Sanders Law" },
    { "text": "It’s not whether you get knocked down, it’s whether you get up.", "author": "Vince Lombardi" },
    { "text": "The best revenge is massive success.", "author": "Frank Sinatra" },
    { "text": "Do one thing every day that scares you.", "author": "Eleanor Roosevelt" },
    { "text": "In the end, we only regret the chances we didn’t take.", "author": "Lewis Carroll" },
    { "text": "Don’t limit yourself. Many people limit themselves to what they think they can do. You can go as far as your mind lets you.", "author": "Mary Kay Ash" },
    { "text": "I never dreamed about success, I worked for it.", "author": "Estee Lauder" },
    { "text": "What seems to us as bitter trials are often blessings in disguise.", "author": "Oscar Wilde" },
    { "text": "Challenges are what make life interesting and overcoming them is what makes life meaningful.", "author": "Joshua J. Marine" },
    { "text": "If you want to achieve greatness stop asking for permission.", "author": "Unknown" },
    { "text": "Success is not the key to happiness. Happiness is the key to success.", "author": "Albert Schweitzer" },
    { "text": "Start where you are. Use what you have. Do what you can.", "author": "Arthur Ashe" },
    { "text": "If opportunity doesn’t knock, build a door.", "author": "Milton Berle" },
    { "text": "Don't wait for your ship to come in, swim out to it.", "author": "Cathy Hopkins" },
    { "text": "Success is liking yourself, liking what you do, and liking how you do it.", "author": "Maya Angelou" },
    { "text": "To be a champ, you have to believe in yourself when no one else will.", "author": "Sugar Ray Robinson" },
    { "text": "Your life does not get better by chance, it gets better by change.", "author": "Jim Rohn" }
  ];
  
  return quotes[Math.floor(Math.random() * quotes.length)];
};

export const useQuote = () => {
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getNewQuote = async () => {
    setLoading(true);
    setError(null);
    try {
      const newQuote = await fetchQuote();
      setQuote(newQuote);
    } catch (err) {
      setError('Failed to fetch quote');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getNewQuote();
  }, []);

  return { quote, loading, error, getNewQuote };
};

export default function QuoteDisplay() {
  const { quote, loading, error } = useQuote();

  if (loading) return <div>Loading quote...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!quote) return null;
  


  return (
    <div className="bg-gray-100 p-4 mb-4 rounded-lg shadow">
      <p className="text-lg italic">
        "{quote.text}" — <span className="text-gray-600">{quote.author}</span>
      </p>
    </div>
  );
};